import { toast } from 'react-toastify';

function pop(message) {
  toast.info(message, {
    icon: false,
    position: 'bottom-right',
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export default { pop };
